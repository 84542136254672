import { Grid, Typography, Link, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Main = () => (
  <>
    <Grid container rowSpacing={1}>
      <Grid item xs={12} sm={4}>
        <Typography variant="h5">Denmark</Typography>
        <Box display="flex" flexDirection="column">
          <Link to="day-1" component={RouterLink} underline="hover">
            June 29th - Day 1
          </Link>
          <Link to="day-2" component={RouterLink} underline="hover">
            June 30th - Day 2
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="h5">Sweden</Typography>
        <Box display="flex" flexDirection="column">
          <Link to="day-3" component={RouterLink} underline="hover">
            July 1st - Day 3
          </Link>
          <Link to="day-4" component={RouterLink} underline="hover">
            July 2nd - Day 4
          </Link>
          <Link to="day-5" component={RouterLink} underline="hover">
            July 3rd - Day 5
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="h5">Norway</Typography>
        <Box display="flex" flexDirection="column">
          <Link to="day-6" component={RouterLink} underline="hover">
            July 4th - Day 6
          </Link>
          <Link to="day-7" component={RouterLink} underline="hover">
            July 5th - Day 7
          </Link>
          <Link to="day-8" component={RouterLink} underline="hover">
            July 6th - Day 8
          </Link>
          <Link to="day-9" component={RouterLink} underline="hover">
            July 7th - Day 9
          </Link>
          <Link to="day-10" component={RouterLink} underline="hover">
            July 8th - Day 10
          </Link>
          <Link to="day-11" component={RouterLink} underline="hover">
            July 9th - Day 11
          </Link>
          <Link to="day-12" component={RouterLink} underline="hover">
            July 10th - Day 12
          </Link>
          <Link to="day-13" component={RouterLink} underline="hover">
            July 11th - Day 13
          </Link>
          <Link to="day-14" component={RouterLink} underline="hover">
            July 12th - Day 14
          </Link>
          <Link to="day-15" component={RouterLink} underline="hover">
            July 13th - Day 15
          </Link>
        </Box>
      </Grid>
    </Grid>
  </>
);

export default Main;
