import { EntryType } from "../../components/pages/entryType";

const posts: Array<EntryType> = [
    {
        date: 'June 29th',
        day: 1,
        location: 'Boston, MA - Copenhagen, DK',
        content: [
            {
                type: 'p',
                text: 'We took a red eye to Copenhagen, and arrived to a beautiful day in the city. We spent some time walking around from our hotel, and got a delicious dinner outside followed by some really good gelato (I got chocolate and olive oil).'
            },
            {
                type: 'img',
                alt: 'Multicolored buildings with tents and outdoor dining from across a canal.',
                source: 'copenhagen',
                caption: 'Nyhavn'
            },
            {
                type: 'img',
                alt: 'Julia sitting at a table with a crepe and salmon.',
                vert: true,
                source: 'salmon',
                caption: 'Dinner outside at a cute cafe.'
            },
            {
                type: 'p',
                text: 'The fire alarm went off in our hotel at around 1am, which wasn\'t so bad for me as I already couldn\'t sleep! It was a false alarm, and we got back to bed fairly quickly.'
            },
            {
                type: 'img',
                alt: 'A firetruck and a crowd of people in front of a hotel.',
                source: 'firealarm',
                caption: ''
            },
        ]
    },
    {
        date: 'June 30th',
        day: 2,
        location: 'Copenhagen, DK - Lund, SE',
        content: [
            {
                type: 'p',
                text: 'It was raining today, and we tried our best to stay dry while walking to breakfast at Seks, which had delicious eggs and rolls from a 120 year old (!) sourdough starter. We later got a cardamom bun and strawberry pastry from Juno the Bakery. Since it was still raining pretty hard, we went to the closest museum, which was the Brumleby museum, in a social housing complex originally built as housing for the poor to avoid unhygienic conditions during the 1853 Copenhagen cholera epidemic. It was fairly random to end up there (and lucky, it\'s only open 4 hours a week!), but cool to see and hear about this small part of the city\'s history.'
            },
            {
                type: 'img',
                alt: 'A building with a yellow first floor and white second floor along a small street.',
                source: 'brumleby',
                caption: 'Brumleby',
                vert: true
            },
            {
                type: 'img',
                alt: 'Old documents on a wooden desk.',
                source: 'desk',
                caption: 'A desk preserved from one of the original shops in Brumleby.'
            },
            {
                type: 'p',
                text: 'After grabbing some smørrebrød for lunch, we caught the train to Lund, Sweden where we met up with Elisabeth and my parents. Elisabeth had just finished her first year of grad school, the last part of which was in Lund.'
            },
            {
                type: 'img',
                alt: 'Lewis, Elisabeth, John, and Barbra around a multicolored Lund sign in a plaza.',
                source: 'lund',
                caption: ''
            },
        ]
    },
    {
        date: 'July 1st',
        day: 3,
        location: 'Lund, SE - Käringön, SE',
        content: [
            {
                type: 'p',
                text: 'We rented a car and drove up the coast of Sweden today, making our way to the ferry to the island of Käringön. It was a beautiful sunny day and there were stunning views from the boat.'
            },
            {
                type: 'img',
                alt: 'Red buildings along the water including Hotell Käringön.',
                source: 'arrive-karingon',
                caption: 'Arriving on the ferry',
            },
            {
                type: 'p',
                text: 'The island was really picturesque, with a bunch of houses clustered around the ferry terminal, but most of the island just rocky with wildflowers and lichen. After walking to our AirBnb, we explored the back of the island before having a lovely seafood dinner on the water.'
            },
            {
                type: 'img',
                alt: 'Pink flowers in the foreground in front of rocks and a bathhouse.',
                source: 'karingon-flowers',
                caption: ''
            },
            {
                type: 'img',
                alt: 'White buildings with orange roofs on the coast of an island.',
                source: 'karingon-house',
                caption: ''
            },
        ]
    },
    {
        date: 'July 2nd',
        day: 4,
        location: 'Käringön, SE',
        content: [
            {
                type: 'p',
                text: 'We had a relaxed day on the island today. We walked to the end of the island and checked out the bathing area which had a diving board! There were a lot of jellyfish around, so we didn\'t jump in from the diving board, but I still managed a quick dip unstung off of a nearby rock.'
            },
            {
                type: 'img',
                alt: 'White letters on a rock with an arrow to "BAD".',
                source: 'karingon-bath',
                caption: 'Trail to the bathing area',
            },
            {
                type: 'img',
                alt: 'Jellyfish and seaweed.',
                source: 'jellyfish',
                caption: 'Yikes!',
            },
            {
                type: 'p', 
                text: 'We got some crayfish for lunch and some fish to grill for dinner at the fish market.'
            },
            {
                type: 'img',
                alt: 'John holding crayfish in front of Julia and Lewis and Julia laughing.',
                source: 'crayfish',
                caption: 'The crayfish were delicious!'
            },
            {
                type: 'img',
                alt: 'A dinner table with cod, potatoes, carrots and asparagus.',
                source: 'homemade-dinner',
                caption: 'Grilled fish dinner',
                vert: true
            },
        ]
    },
    {
        date: 'July 3rd',
        day: 5,
        location: 'Käringön, SE - Oslo, NO',
        content: [
            {
                type: 'p',
                text: 'We walked across the rocks one more time before leaving the island to drive further up the coast to Norway.'
            },
            {
                type: 'img',
                alt: 'Elisabeth, Barbra, John, Lewis, and Julia against a background of rocks and the ocean.',
                source: 'family-karingon',
                caption: '',
            },
            {
                type: 'img',
                alt: 'View from the back of a ferry with the Swedish flag and Käringön in the background.',
                source: 'leaving-karingon',
                caption: 'View from the ferry leaving Käringön',
            },
            {
                type: 'p', 
                text: 'We had lunch at a small cafe and tried the Swedish dish Flying Jacob, which has chicken, cream, bacon, chili sauce, peanuts, and bananas(!).'
            },
            {
                type: 'img',
                alt: 'Flying jacob with rice and salad.',
                source: 'flying-jacob',
                caption: 'It was delicious!'
            },
            {
                type: 'p', 
                text: 'We drove north to Oslo, and walked around a bit through downtown.'
            },
            {
                type: 'img',
                alt: 'The Oslo Opera House.',
                source: 'oslo-operahouse',
                caption: 'The Oslo Opera House',
            },
        ]
    },
    {
        date: 'July 4th',
        day: 6,
        location: 'Oslo, NO',
        content: [
            {
                type: 'p',
                text: 'We spent the day wandering around Oslo, checking out some cafes and the Munch museum. We saw The Scream, as well as a bunch of different versions of his painting The Sun. My favorite was the enormous one.'
            },
            {
                type: 'img',
                alt: 'Julia standing beside a huge painting of a sun over a valley.',
                source: 'the-sun',
                caption: 'A huge version of The Sun',
            },
            {
                type: 'img',
                alt: 'The Oslo Opera House.',
                source: 'munch-museum',
                caption: 'The Oslo Opera House and Munch Museum',
            },
        ]
    },
    {
        date: 'July 5th',
        day: 7,
        location: 'Oslo, NO',
        content: [
            {
                type: 'p',
                text: 'We explored the Nordre Øyeren nature preserve today, which was just a short drive from Oslo, but felt pretty remote. We saw a few other folks (mostly birders) but mostly just sheep!'
            },
            {
                type: 'img',
                alt: 'A sheep alongside a grassy path in front of a lake.',
                source: 'nordre-oyeren',
                caption: '',
            },
        ]
    },
    {
        date: 'July 6th',
        day: 8,
        location: 'Oslo, NO - Vågåmo, NO',
        content: [
            {
                type: 'p',
                text: 'We left Oslo today and drove up to Vågåmo. We went on a short hike when we arrived, which was nice despite some rain.'
            },
            {
                type: 'img',
                alt: 'Julia walking up a narrow trail with low greenery.',
                source: 'liafjellet',
                caption: '',
            },
            {
                type: 'p',
                text: 'Our airbnb had an amazing view (and a sod roof!), and all the scenery here was incredible. We had a lovely dinner at Andre Etasje in town.'
            },
            {
                type: 'img',
                alt: 'Trout and cream on a plate.',
                source: 'andre-etasje',
                caption: 'Delish!',
            },
            {
                type: 'img',
                alt: 'Looking out over a lake with a cabin in the foreground.',
                source: 'vagamo-view',
                caption: 'Stunning view from right out our front door.',
            },
        ]
    },
    {
        date: 'July 7th',
        day: 9,
        location: 'Vågåmo, NO',
        content: [
            {
                type: 'p',
                text: 'It was quite rainy today, so we decided to just walk around town and save our hiking for clearer weather. We had a nice lunch in town, and saw the town church.'
            },
            {
                type: 'img',
                alt: 'The interior of a church with really intricate woodworking.',
                source: 'vagamo-church',
                caption: 'Inside the Vågåmo church',
            },
        ]
    },
    {
        date: 'July 8th',
        day: 10,
        location: 'Vågåmo, NO',
        content: [
            {
                type: 'p',
                text: 'We took a guided hike to the top of Galdhøpiggen today, the tallest mountain in Norway. Because it crossed a glacier, we had to go with a guide and rope up for the crossing. Unfortunately we were completely in a cloud at the top so didn\'t have much of a view, but it was still really epic. It\'s also just fun getting a snowy day in July!'
            },
            {
                type: 'img',
                alt: 'Julia and a line of others roped together crossing a glacier.',
                source: 'glacier',
                caption: 'Crossing the glacier',
            },
            {
                type: 'img',
                alt: 'A sign warning of the danger of crevasses.',
                source: 'crevasse',
                caption: 'No one fell into a crevasse, but we were prepared!',
                vert: true,
            },
            {
                type: 'img',
                alt: 'Lewis standing in front of a door at the top of the mountain.',
                source: 'galdhopiggen',
                caption: 'Hut on top of Galdhøpiggen',
                vert: true,
            },
        ]
    },
    {
        date: 'July 9th',
        day: 11,
        location: 'Vågåmo, NO - Flo, NO',
        content: [
            {
                type: 'p',
                text: 'We hiked the Knutshøe ridge today, which was breathtakingly beautiful. Most of the hike was on a narrow ridge with stunning 360 degree views the entire way.'
            },
            {
                type: 'img',
                alt: 'Julia spreading her arms on top of Knutshøe with lakes and mountains in the background.',
                source: 'knutshoe',
                caption: '',
            },
            {
                type: 'img',
                alt: 'A view of the ridge from below.',
                source: 'knutshoe-ridge',
                caption: '',
                vert: true,
            },
            {
                type: 'p',
                text: 'We then drove to our next airbnb in Flo, and just the drive there was amazing. The road went through long tunnels in the mountains, before popping out to crazy views. The town of Flo was small and along a one lane road through some long tunnels, which made for a bit of a nerve-wracking drive.'
            },
            {
                type: 'img',
                alt: 'A narrow valley between mountains with a road and village in the middle, and snow capped mountains in the distance.',
                source: 'driving-to-flo',
                caption: '',
            },
        ]
    },
    {
        date: 'July 10th',
        day: 12,
        location: 'Flo, NO',
        content: [
            {
                type: 'p',
                text: 'We hiked to Bødalsbreen (a glacier) today, part of the larger Jostedalsbreen (the largest in mainland Europe). While hiking up the valley, we saw many huge waterfalls pouring down from the glacier.'
            },
            {
                type: 'img',
                alt: 'A glacier between two rocky peaks.',
                source: 'bodalsbreen',
                caption: 'The glacier',
            },
            {
                type: 'img',
                alt: 'A foggy valley with a tall waterfall.',
                source: 'bodalsbreen-waterfall',
                caption: '',
            },
            {
                type: 'img',
                alt: 'Lewis and Julia taking shelter under a rock.',
                source: 'bodalsbreen-shelter',
                caption: 'Staying dry for a snack',
                vert: true,
            },
        ]
    },
    {
        date: 'July 11th',
        day: 13,
        location: 'Flo, NO',
        content: [
            {
                type: 'p',
                text: 'We started the day by hiking right from our airbnb to Flofjellet, a mountain valley above the town of Flo. We hiked up mostly in a cloud but were rewarded with great views from the top.'
            },
            {
                type: 'img',
                alt: 'Two cows in front of snow covered mountains with patches of fog.',
                source: 'flofjellet-cows',
                caption: 'Cows!',
            },
            {
                type: 'img',
                alt: 'Lewis walking down a path in a valley with mountains in the distance.',
                source: 'down-to-flo',
                caption: '',
                vert: true,
            },
            {
                type: 'img',
                alt: 'A view of Flo from the valley above.',
                source: 'flo',
                caption: 'The town of Flo from above',
            },
            {
                type: 'p',
                text: 'In the afternoon, we hiked to another glacier, Briksdalsbreen.'
            },
            {
                type: 'img',
                alt: 'A glacier.',
                source: 'briksdalsbreen',
                caption: '',
                vert: true,
            },
            {
                type: 'p',
                text: 'As a bonus, our airbnb hosts had many cats, including these two adorable kittens.'
            },
            {
                type: 'img',
                alt: 'A striped grey kitten jumping over a calico kitten.',
                source: 'flo-kittens',
                caption: '',
                vert: true
            },
        ]
    },
    {
        date: 'July 12th',
        day: 14,
        location: 'Flo, NO - Nesbyen, NO',
        content: [
            {
                type: 'p',
                text: 'We hiked Årheimsfjellet today, before a fairly long drive to Nesbyen to prepare to fly home tomorrow. We again hiked up mostly in a cloud which cleared by the time we were at the top.'
            },
            {
                type: 'img',
                alt: 'Bright purple bell flowers.',
                source: 'loen-flowers',
                caption: 'Beautiful wildflowers along the way',
                vert: true
            },
            {
                type: 'img',
                alt: 'Julia on top of a mountain with wide open views of valleys and snow capped mountains.',
                source: 'arheimsfjellet',
                caption: '',
            },
            {
                type: 'img',
                alt: 'A glacier.',
                source: 'nordfjord',
                caption: 'Views of Nordfjord on the way down',
            },
        ]
    },
    {
        date: 'July 13th',
        day: 15,
        location: 'Nesbyen, NO - Medford, MA',
        content: [
            {
                type: 'p',
                text: 'We drove back to Oslo and flew home today.'
            },
        ]
    },
]

export default posts